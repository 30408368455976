import { FC } from 'react';

interface PlayIconProps {
  fill?: string;
  size?: string;
}
const PlayIcon: FC<PlayIconProps> = ({ fill, size }) => (
  <svg
    width={size || '463'}
    height={size || '462'}
    viewBox="0 0 463 462"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.5" cx="231.742" cy="230.999" r="231" fill={fill || 'white'} />
    <path
      d="M181.703 165.53C181.703 156.392 191.812 150.873 199.499 155.814L301.34 221.283C308.412 225.83 308.412 236.168 301.34 240.715L199.499 306.184C191.812 311.125 181.703 305.606 181.703 296.468V165.53Z"
      fill={fill || 'white'}
    />
  </svg>
);

export default PlayIcon;
