import { IconProps } from './IconProps';

function ArrowTopRight(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'ArrowTopRight 12px';

  return (
    <svg height={height} width={width} viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ArrowTopRight">
        <path
          id="Vector"
          d="M9.79293 104.306C6.20094 100.714 6.20095 94.8898 9.79295 91.2978L81.3357 19.755H39.4924C31.7983 19.755 27.9451 10.4525 33.3856 5.01194C35.0053 3.39232 37.2019 2.48242 39.4924 2.48242H111.616V74.0915C111.616 76.4385 110.684 78.6894 109.024 80.349C103.449 85.9238 93.9172 81.9755 93.9172 74.0915V33.1893L22.8008 104.306C19.2087 107.898 13.3849 107.898 9.79293 104.306Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default ArrowTopRight;
