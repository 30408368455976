"use client";
import { Dialog, Transition } from "@headlessui/react";
import { useState, useEffect, Fragment } from "react";
import CtaLink from "components/CtaLink";
import Image from "next/image";

interface PopupCTAProps {
  label: string;
  modalHeading?: string | null;
  modalDescription?: string | null;
  variant?: string;
  size?: string;
  ctaList?: {
    [key: string]: string;
  };
}

const PopupCTA: React.FC<PopupCTAProps> = ({ label, variant, size, modalHeading, modalDescription, ctaList }) => {
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  if (!mounted) {
    return null;
  }

  return (
    <>
      <CtaLink label={label} onClick={openModal} variant={variant} size={size} />
      <Transition show={isOpen} as={Fragment}>
        <Dialog onClose={closeModal} className="relative z-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary-dark-700/50" aria-hidden="true" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative mx-auto max-w-xl rounded-lg bg-primary-neutral-50 p-6 shadow-lg">
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-600 absolute right-2 top-2"
                  aria-label="Close"
                >
                  <Image src="/assets/icons/e-remove-glyph-24.svg" alt="Close" width={24} height={24} />
                </button>
                {modalHeading && <h2 className="text-3xl font-bold">{modalHeading}</h2>}
                {modalDescription && <p className="py-3">{modalDescription}</p>}
                {ctaList ? (
                  <div className="mt-4 flex flex-col gap-2 md:flex-row">
                    {ctaList.url && ctaList.label && (
                      <a
                        href={ctaList.url}
                        className="btn btn-primary flex w-full items-center justify-center whitespace-nowrap rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650 px-4 py-4 font-bold leading-none text-primary-neutral-50 shadow-md transition duration-300 hover:shadow-lg"
                        onClick={closeModal}
                      >
                        {ctaList.label}
                      </a>
                    )}
                    {ctaList.url2 && ctaList.label2 && (
                      <a
                        href={ctaList.url2}
                        className="btn btn-primary flex w-full items-center justify-center whitespace-nowrap rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650 px-4 py-4 font-bold leading-none text-primary-neutral-50 shadow-md transition duration-300 hover:shadow-lg"
                        onClick={closeModal}
                      >
                        {ctaList.label2}
                      </a>
                    )}
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupCTA;
