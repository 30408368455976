'use client';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Document } from '@contentful/rich-text-types';
import { FC, useState } from 'react';
import { HiOutlineClipboard, HiOutlineClipboardCheck } from 'react-icons/hi';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/prism-light';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import css from 'react-syntax-highlighter/dist/esm/languages/prism/css';
import git from 'react-syntax-highlighter/dist/esm/languages/prism/git';
import graphql from 'react-syntax-highlighter/dist/esm/languages/prism/graphql';
import js from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import markup from 'react-syntax-highlighter/dist/esm/languages/prism/markup';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import sql from 'react-syntax-highlighter/dist/esm/languages/prism/sql';
import yaml from 'react-syntax-highlighter/dist/esm/languages/prism/yaml';
import a11yDark from 'react-syntax-highlighter/dist/esm/styles/prism/a11y-dark';

const languageGrammar = new Map();

languageGrammar.set('bash', bash);
languageGrammar.set('css', css);
languageGrammar.set('git', git);
languageGrammar.set('graphql', graphql);
languageGrammar.set('javascript', js);
languageGrammar.set('json', json);
languageGrammar.set('markup', markup);
languageGrammar.set('python', python);
languageGrammar.set('sql', sql);
languageGrammar.set('yaml', yaml);
languageGrammar.set('html', markup);
languageGrammar.set('cshtml', markup);

interface CodeSnippetProps {
  node: any;
}

const CodeSnippets: FC<CodeSnippetProps> = ({ node }) => {
  const language = node?.language?.toLowerCase() === "html" ? "cshtml" : node?.language?.toLowerCase() || "javascript";
  if (languageGrammar.get(language)) {
    SyntaxHighlighter.registerLanguage(language, languageGrammar.get(language));
  }
  const [copied, setCopied] = useState(false);

  let code: string;

  if (node?.code?.json) {
    code = documentToPlainTextString(node?.code?.json as Document);
  } else {
    code = node?.code;
  }

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(code);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="relative block w-full">
      <SyntaxHighlighter
        language={language}
        style={a11yDark}
        className="m-0 overflow-auto rounded-lg !p-xs !pr-md text-sm"
      >
        {code}
      </SyntaxHighlighter>
      <button
        className="group absolute right-0 top-0 z-10 m-[7px] flex cursor-pointer items-center rounded bg-primary-neutral-100 px-[3px] py-[6px] text-xl opacity-80 transition-opacity will-change-[opacity] hover:opacity-100"
        onClick={handleCopy}
        aria-label="Copy to clipboard"
      >
        {copied ? (
          <HiOutlineClipboardCheck className="transform transition-transform duration-300 ease-in-out will-change-transform group-active:scale-110" />
        ) : (
          <HiOutlineClipboard className="transform transition-transform duration-300 ease-in-out will-change-transform group-active:scale-110" />
        )}
      </button>
    </div>
  );
};

export default CodeSnippets;
