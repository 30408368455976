import { cn } from "utils/functions";

const heading = {
  cardHeading: cn("font-body text-base font-medium leading-tight xl:text-lg 2xl:text-xl"),
  innerSubheading: cn("font-display text-xl font-bold leading-tight lg:text-2xl 2xl:text-3xl"),
  secondaryComponentHeading: cn("font-display text-lg font-bold leading-tight md:text-xl 2xl:text-2xl"),
  componentHeading: cn("font-display text-xl font-bold leading-extra-tight md:text-2xl xl:text-3xl 2xl:text-3_5xl"),
  longHeadline: cn("font-display text-3xl font-black leading-tight md:text-3xl xl:text-4xl 2xl:text-4_5xl"),
  sectionHeading: cn("font-display text-3xl font-bold leading-tight xl:text-4xl"),
  pageHeading: cn("font-display text-3xl font-black leading-extra-tight sm:text-4xl lg:text-5xl xl:text-6xl"),
  homepageHeading: cn(
    "font-display text-3xl font-black leading-extra-tight sm:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl",
  ),
  extraBigHeading: cn(
    "font-display text-4xl font-black leading-tight sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-[80px]",
  ),
  lowCodeHeading: cn(
    "font-display text-3xl font-black leading-extra-tight sm:text-4xl lg:text-5xl xl:text-6xl 2xl:text-8xl",
  ),
};

const body = {
  xs: cn("font-body text-xs leading-normal"),
  sm: cn("font-body text-xs leading-normal md:text-sm"),
  md: cn("font-body text-base leading-normal"),
  lg: cn("font-body text-lg leading-normal"),
  xl: cn("font-body text-xl leading-normal"),
  cardBody: cn("text-xs lg:text-sm 2xl:text-base"),
  featureText: cn("font-body text-lg leading-tight md:text-xl lg:text-2xl 2xl:text-3xl"),
  componentBody: cn("font-body text-xs leading-normal md:text-sm lg:text-base"),
  secondaryComponentBody: cn("font-body text-base leading-normal xl:text-lg 2xl:text-xl"),
  longFormContent: cn("font-body text-base leading-normal xl:text-lg"),
};

const ctaLink = {
  sm: cn("font-display text-xs font-bold"),
  md: cn("font-display font-bold"),
  lg: cn("font-display font-bold"),
  Small: cn("font-display text-xs font-bold"),
  Medium: cn("font-display font-bold"),
};

const typography = {
  label: cn("font-body text-xs lg:text-sm font-medium leading-snug xl:text-base"),
  inputLabel: cn("text-base"),
  heading,
  body,
  ctaLink,
};

export default typography;
