import { IconProps } from './IconProps';

function ArrowRight(props: IconProps) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'ArrowRight';

  return (
    <svg
      height={height}
      width={width}
      className="scale-125"
      viewBox="0 0 111 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ArrowRight">
        <path
          id="Vector"
          d="M18.1211 55.2251H97.8522"
          stroke={fill}
          strokeWidth="14.045"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M65.2344 24.1611L97.8516 55.2252L65.2344 86.2893"
          stroke={fill}
          strokeWidth="14.045"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default ArrowRight;
